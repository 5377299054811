<template>
  <v-layout wrap>
    <v-flex xs12>
      <app-title title="Cupones de descuento">
        <template slot="subtitle">
          Ultimos 500 cupones enviados
        </template>
        <template slot="button">
          <v-btn color="back" class="mt-0" :to="{ name: 'editDiscountCoupons', params: { id: 0 } }" flat>
            Añadir
          </v-btn>
        </template>
      </app-title>
    </v-flex>
    <v-flex xs12>
      <v-card class="elevation-2">
        <v-toolbar class="elevation-0">
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchText"
            hide-details
            append-icon="search"
            placeholder="Buscar por codigo"
            flat
            solo
            single-line
            right
            @keyup.native="searchInputHandle"
          ></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          :headers="headers"
          :items="items"
          :pagination.sync="pagination"
          :rows-per-page-items="rowsPerPage"
          class="elevation-1"
          no-data-text="No hay codigos de descuento enviados con los datos ingresados"
        >
          <template slot="items" slot-scope="props">
            <tr :active="props.selected">
              <td class="text-xs-left">
                {{ props.item.name }}
              </td>
              <td class="text-xs-right">
                {{ props.item.code }}
              </td>
              <td class="text-xs-right">
                {{ props.item.total }}
              </td>
              <td class="text-xs-left">
                {{ props.item.from }}
              </td>
              <td class="text-xs-left">
                {{ props.item.end }}
              </td>
              <td class="text-xs-center">
                <app-status :value="props.item.status"></app-status>
              </td>
              <td class="text-xs-left">
                <v-btn
                  :to="{
                    name: 'editDiscountCoupons',
                    params: { id: props.item.id }
                  }"
                  flat
                  icon
                >
                  <v-icon small>
                    edit
                  </v-icon>
                </v-btn>
                <v-icon small @click="deleteItemAction(props.item)">
                  delete
                </v-icon>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { GET_API, GET_ITEMS_DISCOUNT, COUPON } from '../../config'
import appTitle from '../useful/title.vue'
import appStatus from '../useful/status.vue'

export default {
  name: 'CuponesDeDescuentos',
  metaInfo: {
    title: 'Cupones de descuento'
  },
  components: {
    appTitle,
    appStatus
  },
  data() {
    return {
      item: {},
      items: [],
      filter: {
        dateStart: this.$moment()
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        dateEnd: this.$moment().format('YYYY-MM-DD'),
        type: 1
      },
      headers: [
        { text: 'Nombre', sortable: false, align: 'left' },
        { text: 'Codigo', sortable: false, align: 'right' },
        { text: 'Cantidad', sortable: false, align: 'right' },
        { text: 'Inicio', sortable: false, align: 'left' },
        { text: 'Valido', sortable: false, align: 'left' },
        { text: 'Estado', sortable: false, align: 'center' },
        { text: 'Acciones', sortable: false, align: 'left' }
      ],
      searchText: null,
      dialogCupon: false,
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }]
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    async deleteItem(item) {
      try {
        await this.$http.delete(`${COUPON}/${item.id}`)
        this.getItems()
        this.$store.dispatch('setConfirm', { active: false })
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getItems() {
      try {
        const res = await this.$http.get(GET_ITEMS_DISCOUNT, {
          params: {
            query: this.searchText
          }
        })
        this.items = res.data
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    deleteItemAction(item) {
      this.$store.dispatch('setConfirm', {
        active: true,
        title: '¿Eliminar cupon?',
        description: 'Se eliminara del sistema, no se puede recuperar',
        action: () => this.deleteItem(item)
      })
    },
    searchInputHandle() {
      this.searchText = this.searchText == null ? '' : this.searchText
      if (this.searchText.length >= 3 || this.searchText.length === 0) {
        this.getItems()
      }
    }
  }
}
</script>
